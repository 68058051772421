@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.footer-image {
  width: -webkit-fill-available;
  max-width: 400px;
  margin: auto;
}
.shadow {
  box-shadow: rgb(0, 0, 0, 0.1) 0px 0px 10px;
}

.logo {
  width: 300px;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.bg-color {
  background-color: #ececec;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transparent {
  background: transparent !important;
}

.ant-layout-sider-trigger {
  border: 1px solid #f2f2f2;
}
.ant-image {
  height: 240px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff4ff !important;
}

.calcom-button {
  background-color: #1e0f54 !important;
  color: #ffffff !important;
  border: none;
  padding: 8px 16px;
  font: bold 14px sans-serif;
  cursor: pointer;
}
